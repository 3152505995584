<template>
  <div class="addProject-containter">
    <nav-menu></nav-menu>
    <div class="addProject-body">
      <div class="product-apply flex-a flex-b">
        <div class="product-left">
          <!-- <div>电子银行保函</div>
          <div>纯信用免保证金、额度高可循环、线上办、费率低、秒开立</div> -->
        </div>
        <!-- <div class="product-right">立即申请</div> -->
      </div>
      <div class="containter flex">
        <div class="addProject-form">
          <div class="addProject-tip flex-a">
            <div><img src="./../assets/waring.png" alt="" /></div>
            <div>仅支持各地公共资源交易中心及政府认可得招投标平台公示得中标项目</div>
          </div>
          <el-form :model="form" :rules="rules" ref="form" label-width="150px" class="demo-ruleForm">
            <el-form-item label="项目编号：" prop="ntNo">
              <el-input v-model="form.ntNo" placeholder="请输入项目编号"></el-input>
            </el-form-item>
            <el-form-item label="项目公告：" prop="ntName">
              <el-input v-model="form.ntName" placeholder="请输入项目公告"></el-input>
            </el-form-item>
            <el-form-item label="中标日期：" prop="ntDate">
              <el-date-picker v-model="form.ntDate" type="date" placeholder="选择中标日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="中标金额：" prop="ntMoney">
              <el-input v-model="form.ntMoney" placeholder="请输入中标金额"></el-input>
            </el-form-item>
            <el-form-item label="中标公示链接：" prop="ntHost">
              <el-input v-model="form.ntHost" placeholder="请输入中标公示页面链接"></el-input>
              <div class="waring-tip flex-a"><img src="./../assets/waring_tip.png"
                  alt=""><span>注：仅支持添加中标/订单时间不超过30天的项目</span></div>
            </el-form-item>
            <el-form-item label="中标通知：" prop="ntUrl">
              <el-upload class="avatar-uploader" style="border: 1px dashed #d9d9d9; width: 100px;" accept=".doc,.docx,.pdf" :action="action" :before-remove="beforeRemove"
                :headers="headers" :on-remove="handleRemove" :limit="1" :on-exceed="handleExceed"
                :on-success="uploadFile" :file-list="fileList">
                <!-- <el-button size="small" type="primary">点击上传</el-button> -->
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
          <div class="addProject-form-btn">
            <div class="btn" @click="submit">提交</div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import baseUrl from '@/lib/utils/baseUrl'
import { postProject, delFile } from '@/lib/api/project'
export default {
  name: 'addProject',
  data() {
    return {
      fileList: [],
      headers: { Authorization: "Bearer " + localStorage.getItem('token') },
      action: baseUrl + '/buiness/upload/comFile',
      form: {
        ntNo: '',
        ntName: '',
        ntDate: '',
        ntMoney: "",
        ntHost: '',
        ntUrl: ''
      },
      rules: {
        ntNo: [
          { required: true, message: '请填写项目编号', trigger: 'blur' }
        ],
        ntName: [
          { required: true, message: '请填写项目公告', trigger: 'blur' }
        ],
        ntDate: [
          { required: true, message: '请选择中标日期', trigger: 'blur' }
        ],
        ntMoney: [
          { required: true, message: '请填写中标金额', trigger: 'blur' }
        ],
        ntHost: [
          { required: true, message: '请填写中标公示页面链接', trigger: 'blur' }
        ],
        ntUrl: [
          { required: true, message: '请上传中标通知', trigger: 'blur' }
        ],
      },
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$alert('请先登录!', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          this.$router.push('/advance')
        }
      });
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 文件上传限制
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //文件上传成功
    uploadFile(res, file, fileList) {
      console.log(res)
      if (res.code == 200) {
        this.form.ntUrl = res.url
      }
    },
    //删除文件
    handleRemove(file, fileList) {
      console.log(file, fileList);
      console.log(this.form)
      delFile({ url: this.form.ntUrl }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '删除成功！',
            type: 'success'
          });
        }
      })
    },
    //删除文件前
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //提交
    submit() {
      postProject(this.form).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message({
            message: '提交成功！',
            type: 'success'
          });
          setTimeout(() => {
            this.$router.push({ path: '/approval', query: {} })
          }, 1000)
        }
      })
    }
  },
  computed: {

  }
}
</script>

<style lang="less" scoped>
.addProject-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .addProject-body {
    padding: 20px 0;

    .product-apply {
      box-sizing: border-box;
      width: 60%;
      height: 260px;
      margin: 0 auto 10px;
      background: url(./../assets/advance.png)no-repeat 0 0;
      background-size: 100%;
      color: #fff;
      font-size: 18px;
    }

    .containter {
      padding: 20px;

      .addProject-form {
        width: 60%;
        margin: 0 auto;

        .addProject-tip {
          background: #E8F2FE;
          border-radius: 5px;
          padding: 5px 10px;
          box-sizing: border-box;
          margin-bottom: 10px;
          font-size: 15px;

          img {
            width: 25px;
            margin-right: 5px;
          }
        }

        .waring-tip {
          font-size: 12px;

          img {
            width: 20px;
            margin-right: 5px;
          }
        }

        .addProject-form-btn {
          div {
            margin: 40px auto 0;
            width: 120px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .demo-ruleForm {
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px #ccc;

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
}
</style>
